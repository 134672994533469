import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PrivacyPage from "../../components/legal/privacy-policy"
const privacypolicy = () => {
  return (
    <Layout>
      <SEO
        title="privacy-policy"
        desc="privacy-policy"
        pathname="/privacy-policy"
        keywords="kyts privacy-policy"
      />
      <PrivacyPage />
    </Layout>
  )
}

export default privacypolicy
